@mixin spans {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.chargeContainer {
  padding: 80px 30px;
  background: rgb(172, 172, 172);
  background: white;
}

.headingText {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 73px;
  letter-spacing: 0.02em;
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: left;
}

.charge {
  @include spans();
  color: #9f268d;
  position: relative;
  cursor: pointer;
}

// the testing hover text begins
.charge:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: max-content;
  background-color: transparent;
  color: grey;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 0.5s ease-in-out;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 85%; //this has been done to position the hover text
}
.charge:hover:before {
  opacity: 1;
  visibility: visible;
}
// the testing ends
.pack {
  @include spans();
  color: #0070c0;
  position: relative;
  cursor: pointer;
}
// the testing text begins
.pack:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: max-content;
  background-color: transparent;
  color: grey;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 0.5s ease-in-out;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  position: absolute;
  z-index: 1;
  left: -75%; //this has been done to position the hover text
  top: 85%;
}
.pack:hover:before {
  opacity: 1;
  visibility: visible;
}
// the testing ends
.grid {
  @include spans();
  color: #112e77;
  position: relative;
  cursor: pointer;
}
// the testing text begins
.grid:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: max-content;
  background-color: transparent;
  color: grey;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 0.5s ease-in-out;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  position: absolute;
  z-index: 1;
  left: -113%; //this has been done to position the hover text
  top: 85%;
}
.grid:hover:before {
  opacity: 1;
  visibility: visible;
}
// the testing ends
.green {
  @include spans();
  color: #78c156;
  position: relative;
  cursor: pointer;
}
// the testing text begins
.green:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: max-content;
  background-color: transparent;
  color: grey;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 0.5s ease-in-out;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  position: absolute;
  z-index: 1;
  // left: -150%;       //this has been done to position the hover text
  left: -50%; //this has been done to position the hover text
  top: 85%;
}
.green:hover:before {
  opacity: 1;
  visibility: visible;
}
// the testing ends
.pack {
  @include spans();
  color: #0070c0;
}

.grid {
  @include spans();
  color: #112e77;
}

.green {
  @include spans();
  color: #78c156;
}

.leftSection {
  padding-left: 50px;
  text-align: left;
}

// added class
.leftSection span {
  margin: auto 10px;
}

.leftSection span {
  margin-left: 15px;
}

.imageAppStore {
  text-align: left;
  margin-top: 10px;
}


@media screen and (max-width: 576px) {
  .imageAppStore {
    margin-top: 3rem !important;
  }
}
// added class
.imageAppStore img {
  margin: auto 20px;
}

.imageAppStore > img {
  margin-left: 0;
}

.appStoreIcon,
.playStoreIcon {
  width: 49px !important;
  height: 49px !important;
  cursor: pointer;
}

.cagrContainer {
  // margin: 100px 0px;
}

.cagrImage {
  width: 100%;
  height: auto;
}

.cagrHeading {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  color: #000;
  text-align: left;
  padding-left: 80px;
}

.cagrText {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: justify;
  padding-left: 80px;
  color: #929292;
}

.cagrGraph {
  width: 100%;
  height: auto;
}

.cagrgraphHeading {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  color: #000000;
  text-align: left;
}

.cagrgraphText {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
  color: #929292;
  padding-right: 15%;
}

.growContent {
  text-align: left;
  margin-bottom: 40px;
  h1 {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.04em;
  }
}

.growGraphImage {
  width: 100%;
  height: auto;
}

.multiple {
  color: #83a954;
}

.growItems {
  padding: 21px 20px 21px 16px;
  gap: 10px;
  background: #000;
  box-shadow: 0px 3px 80px rgba(125, 149, 169, 0.12);
  text-align: left;
  margin-bottom: 10px;
  color: #fff;
  margin-right: 15px;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.04em;
  font-feature-settings: "liga" off;
}

.growContainer {
  margin: 50px 50px;
  // padding: 30px;
}

@media screen and (max-width: 900px) and (orientation: landscape),
  (orientation: portrait) {
  .headingText {
    margin-bottom: 8px !important;
  }

  .charge {
    font-size: 18px !important;
    margin: auto 0px !important;
  }

  .pack {
    font-size: 18px !important;
    margin: auto 0px !important;
  }
  .grid {
    font-size: 18px !important;
    margin: auto 0px !important;
  }
  .green {
    font-size: 18px !important;
    margin: auto 0px !important;
  }

  .allCharges {
    margin-bottom: 6px;
  }

  // .imageAppStore{
  //   margin-top: 10px !important;
  // }

  .gaping {
    margin: auto 5px !important;
  }

  // for the hover text
  .charge:before {
    top: 65%;
    font-size: 13px;
  }
  .pack:before {
    top: 65%;
    left: -110%;
    font-size: 13px;
  }
  .grid:before {
    top: 65%;
    left: -125%;
    font-size: 13px;
  }
  .green:before {
    top: 65%;
    left: -130%;
    font-size: 13px;
  }
}

// //whyzizi
.ziziContainer {
  text-align: center;
  padding-top: 90px;
}

.zizitextContainer {
  width: 100%;
  font-weight: bold;
}

.zizitextContainer p {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.03em;
  text-align: center;
}

.zizitextContainer h3 {
  font-family: "Kanit";
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  width: 70%;
  margin: auto;
}

.ziziColumns {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(213, 213, 213, 0.25);
  padding: 78px 16px 93px 35px;
  gap: 10px;
  text-align: left;
}

.cardContent {
  text-align: left;
  font-family: Kanit;
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.defaultItems p {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  text-align: left;
  align-items: flex-start;
  min-height: 100px;
  color: #000000;
}

.hoveredColumn {
  background-color: black;
  color: white;
  // width: 100%;
  // height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  p {
    text-align: left;
  }
}

// media query for the mobile device



@media (max-width: 576px) {
  .zizitextContainer {
    width: 90%;
  }

  .zizitextContainer p {
    font-family: Kanit;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: center;
  }
  .ziziContainer {
    padding-top: 10px;
  }
  .zizitextContainer h3 {
    font-family: Kanit;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  // zizi card styling

  .ziziColumns {
    align-items: center;
    justify-content: center;
  }

  .zizicardContent p {
    text-align: left;
  }

  .zizicardContent {
    text-align: left;
    font-family: Kanit;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
  }

  .buttonText {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}

// for mobile screen based on query

@media screen and (min-width: 567px) and (max-width: 768px) and (orientation: landscape){
 .zizitextContainer h3{
  font-size: 27px;
  width: 90%;
 }
}

@media screen and (max-width: 576px) {
  .growContent {
    text-align: center;
    margin-bottom: 50px;
    h1 {
      font-family: "Kanit";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
    }
  }
  .leftSection .gaping {
    margin: 0px 5px !important;
  }

  .cagrContainer {
    margin: 80px 30px !important;
    display: flex;
    flex-direction: column-reverse;
  }

  .cagrGraphContainer {
    margin: 80px 30px !important;
  }

  .cagrgraphHeading {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    padding-right: 0px;
  }

  .cagrgraphText {
    padding-right: 0px;
    font-family: "Kanit";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
  }

  .cagrHeading {
    padding: 0px !important;
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
  }

  .cagrText {
    padding: 0px !important;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
  }

  .growContainer {
    margin: 0px;
    padding: 0px;
  }

  .growItemsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 15px;
  }

  .growItems {
    margin: 0px;
    padding: 11px;
    font-family: "Kanit";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
  }

  .textDataContainer {
    padding-right: 0px !important;
  }

  .ziziColumns {
    box-shadow: 0px 2px 10px 0px #d5d5d540 !important;
    padding: 0px;
  }

  .zizitextContainer p {
    font-family: Kanit;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: center;
  }
}

@media screen and (max-width: 900px) {
  .growItems {
    font-size: 11px;
  }
}

@media screen and (max-width: 767px){
  .growItems {
    font-size: 18px;
  }
}

//contact

// for the concact us container

.mainContainer {
  background: linear-gradient(
    270deg,
    #fbfbfb 15.21%,
    #ffffff 75.53%,
    #ffffff 80.9%,
    #e1e1e1 100%
  );
  padding: 50px 0px;
}

.leftPlaceholder {
  background-color: transparent;
}

.textDataContainer {
  padding-right: 80px;
}

// .textContainer {
//   text-align: left;
//   justify-content: center;

//   padding-top: 135px;
//   padding-bottom: 120px;
//   font-family: "Kanit";
// }

.textDataContainer button {
  height: 48px;
  width: 144px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  background: #000000;
  color: white;
  margin-top: 10px;
}

// for the title text

.titleContainer p {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0.04em;
  font-feature-settings: "liga" off;
  color: #000000;
  text-align: left;
}

.titleContainer h4 {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #7e7e7e;
}

.formContainer {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.formContainer input::-webkit-input-placeholder {
  color: #000 !important;
}

.leftPlaceholder {
  padding-left: 0 !important;
}

@media screen and (max-width: 576px) {
  // contanct container
  .contactContainer {
    height: 756px;
  }

  .imageContainer {
    width: 80%;

    margin-bottom: 20%;
  }

  .textDataContainer {
    width: 100%;
    margin-bottom: 38%;
  }

  .titleContainer p {
    font-family: Kanit;
    font-size: 26px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: center;
  }

  .titleContainer h4 {
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .formContainer {
    padding: auto 6%;
  }

  // contanct container

  .imageContainer {
    width: 80%;
    margin-bottom: 20%;
  }

  .textContainer {
    width: 100%;
    // margin-bottom: 38%;
  }

  .titleContainer p {
    font-family: Kanit;
    font-size: 26px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: center;
  }

  .titleContainer h4 {
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .formContainer {
    padding: auto 6%;
  }
}

@media screen and (min-width: 567px) and (max-width: 768px) and (orientation: landscape){
  .imageContainer{
    max-width: 75%;
    height: auto;
  }
}

//navigate
.NavigateContainer {
  background: #f9f9f9;
  padding: 30px 30px;
}

.cardContent {
  background: #000000;
  border-radius: 2px;
  width: 120px;
  height: 120px;
  color: #ffffff;
  gap: 9px;
  text-align: center;

  img {
    filter: invert(1);
  }
}

.cardContentInactive {
  background: #ffffff;
  border-radius: 2px;
  width: 120px;
  height: 120px;
  color: #000;
  gap: 9px;
  cursor: pointer;
  text-align: center;

  p {
    margin-bottom: 0 !important;
  }
}

.cardContent img {
  margin-top: 5px;
  margin-bottom: 5px;
}

.cardContent p {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0 !important;
}

.cardContent:hover {
  background: black;
  color: white;
  cursor: pointer;

  img {
    filter: invert(1);
  }
}

// second column
.mainimage {
  width: 60%;
}

// third column
.textContent {
  text-align: left;
  //   margin-top: 200px;
}

.navigateTextContainer h4 {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.11em;
  text-align: left;
}

.navigateTextContainer h3 {
  font-family: Kanit;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}

.navigateTextContainer p {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.inactiveLink {
  /* Body xs/SemiBold */

  // font-family: "Inter";
  // font-style: normal;
  // font-weight: 600;
  // font-size: 12px;
  // line-height: 20px;
  // /* identical to box height, or 133% */

  // text-align: center;
  // font-feature-settings: "liga" off;
  // background-color: black;
  // color: white;

  // border-radius: 2px;

  margin: auto 15px;

  padding: 8px 12px;
  gap: 8px;
  width: 70px;
  height: 30px;
  background: black !important;
  border-radius: 2px !important;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: #ffffff;
  border: none;
}

.buttonContainer button:first-child {
  margin-left: 0px;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
}

.buttonContainer button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.clickedLink {
  margin: auto 15px;

  padding: 8px 12px;
  gap: 8px;
  width: 60px;
  height: 30px;
  background: white !important;
  border-radius: 2px !important;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: black;
  border: none;
}

.logoContainer {
  margin-top: 15px;
}



@media screen and (max-width: 576px) {
  .cardContent {
    width: 100px;
    height: 100px;
  }
  .mobImage {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 450px !important;
    margin-bottom: 0;
  }
  .cardContentInactive {
    width: 100px;
    height: 100px;
  }
  .NavigateContainer {
    background: #f9f9f9;
    padding: 50px 0px;
  }

  // for first column of tabs
  .tabsContainer {
    width: 30%;
  }

  // for second column of image
  .imageContainer {
    width: 60%;
    height: 100%;
    text-align: right;
  }

  .imageContainer img {
    width: auto;
    height: auto;
  }

  // for third column of text

  .textContent {
    padding-top: 5%;
  }

  .navigateTextContainer h4 {
    font-family: Kanit;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.11em;
    text-align: left;
    margin-bottom: 15px !important;
  }

  .navigateTextContainer h3 {
    font-family: Kanit;
    font-size: 22.5px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 10px !important;
  }

  .navigateTextContainer p {
    font-family: Kanit;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: justified;
    color: #929292;
  }

  .inactiveLink {
    width: 65px;
    height: 30px;
    margin: auto 10px;
    padding: 4px 6px;
    border: none;
    border-radius: 2px;
    font-size: 10px;
  }

  .buttonContainer button:first-child {
    margin-left: 0px;
  }

  .clickedLink {
    // width: 40px;
    // height: 20px;
    // background-color: white;
    // color: black;
    // margin: auto 5px;
    // border: none;

    width: 65px;
    height: 30px;
    margin: auto 10px;
    padding: 4px 6px;
    border: none;
    border-radius: 2px;
    font-size: 10px;
  }

  .logoContainer {
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .cardContent{
    width:100px;
    height:100px;
  }

  .cardContentInactive{
    width:100px;
    height:100px;
  }

  .tabNavColumn{
    height: 460px;
  }
}

//mainfeature

.featureContainer {
  text-align: center;
  margin: 10px 0px;
}

.textContainer {
  width: 60%;

  p {
    font-family: Kanit;
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0.04em;
    text-align: center;
  }

  // h3 {
  //   font-family: Kanit;
  //   font-size: 36px;
  //   font-weight: 600;
  //   line-height: 50px;
  //   letter-spacing: 0.04em;
  //   text-align: center;
  // }
}

.mainCardContent {
  height: 241px;
  font-family: Kanit;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(213, 213, 213, 0.25);
  padding: 45px 30px;
  gap: 10px;
}

@media (max-width: 600px) {
  .textContainer {
    p {
      font-family: Kanit;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: center;
    }

    h3 {
      font-family: Kanit;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.04em;
      text-align: center;
    }
  }

  .mainCardContent {
    font-family: Kanit;
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(213, 213, 213, 0.25);
  }
}

@media screen and (max-width: 576px) {
  .textContainer {
    width: 90%;
  }

  .textContainer p {
    font-family: Kanit;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.04em;
    text-align: center;
    width: 100%;
  }

  .mainCardContent p {
    font-family: Kanit;
    font-size: 13px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
    // background: #FFFFFF;
    // box-shadow: 0px 2px 10px rgba(213, 213, 213, 0.25);
  }
}

@media (max-width: 556px) {
  .headingText {
    font-size: 40px;
    line-height: 50px;
  }

  .leftSection {
    padding-left: 0px;
    text-align: center;
  }

  .rightSectionImage {
    margin-top: 20px;
    width: 292px;
    height: 232px;
  }
}

// for mobile screens

.mainHeading {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #000000;
  text-align: start;
  margin-top: 30px;
  margin-bottom: 50px;
  padding-left: 50px;
  h3 {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.03em;
  }
}

.productImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 28px;
  gap: 8px;
  background: #f9f9f9;
  width: 50%;
  min-width: 350px;
  min-height: 350px;
  height: 50%;
  position: relative;
}

.productImage img {
  width: 50%;
  //   min-width: 315px;
  //   min-height: 315px;
  height: 50%;
}

.hoverProducts {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}

// .productImage:hover .hoverProducts{
//     display: block;
//     width: 100%;
//     text-align: left;
//     position: absolute;
// }

.productImage:hover .hoverProducts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 28px;
}

.productText {
  padding: 0px;
}

.productText h5 {
  font-family: Kanit;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.productText p {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 5px;
  text-align: left;
}

.colorFirst {
  color: #ed7066;
}

.colorSecond {
  color: #83c3ed;
}

.productText {
  text-align: left;
  margin-top: 20px;
  width: 70%;
}
.rightSectionImage {
  width: 100%;
  height: 100%;
  //   width: 788px;
  // height: 531px;
}

@media screen and (max-width: 576px) {
  .productImage:hover .hoverProducts {
    display: none;
  }

  .productText {
    text-align: center;
    width: 100% !important;
  }

  .productText p,
  h5 {
    text-align: center !important;
  }

  .chargeContainer {
    display: flex;
    flex-direction: column-reverse;
  }

  .rightSectionImage {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
    width: 292px;
    height: 232px;
  }

  .headingText {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: 10px !important;
  }

  .charge span {
    margin-left: 0px !important;
    margin-right: 10px !important;
  }

  .leftSection span {
    margin: 0px !important;
  }

  .allCharges {
    float: left !important;
    text-align: left !important;
    width: 100%;
  }

  .charge,
  .pack,
  .grid,
  .green {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  // for main heading
  .mainHeading {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    font-feature-settings: "liga" off;
    color: #000000;
    text-align: left;
    padding-left: 8px;
  }

  // for product image
  .productImage {
    display: flex;

    align-items: center;
    justify-content: center;
    padding: 40px 85px;
    gap: 10px;

    width: 90%;
    height: fit-content;

    background: #f9f9f9;
    margin-top: 30px;
  }

  .productImage img {
    width: 80%;
  }

  //   for product text
  .productText {
    text-align: left;
    margin-top: 20px;
    padding-left: 20px;
  }

  .colorFirst {
    color: #ed7066;
  }

  .colorSecond {
    color: #83c3ed;
  }

  .productText h5 {
    font-family: Kanit;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
  }

  .productText p {
    font-family: Kanit;
    font-size: 10px;
    font-weight: 400;
    line-height: 1px;
    letter-spacing: 0px;
    text-align: left;
  }
}

// for iphone bug fix

@media screen and (min-width: 567px) and (max-width: 768px) and (orientation: landscape) {
// charge section begins
  .chargeContainer {
    padding: 117px 30px !important;
}

  .leftSection {
    padding-left: 0px;
  }

  .headingText {
    font-size: 37px !important;
    line-height: 43px !important;
  }

  .gaping {
    margin: auto 5px !important;
  }

  .imageAppStore img {
    margin: auto 8px;
  }

  .rightSectionCharge{
    padding-inline: 0px !important;
  }
  // charge section ends

  .cagrHeading{
    padding-left: 30px;
  }

  .cagrText{
    padding-left: 30px;
  }
}

// iphone bug fix ends

@media screen and (max-width: 975px) {
  .rightSectionImage {
    width: 100%;
    height: auto;
  }
  .headingText {
    font-size: 45px;
    line-height: 54px;
  }
  .chargeContainer {
    padding: 100px 30px;
  }
}
@media screen and (min-width: 900px) {
  .imageContainer {
    height: 510px;
  }
  .leftSection span {
    margin-left: 10px;
  }

  .gaping {
    margin: auto 0px !important;
  }



  
  .mobImage {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px !important;
    max-height: 510px !important;
    margin-bottom: 0;
    .mainimage {
      height: 100%;
      width: auto;
    }
    .Dashimage {
      width: 80%;
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1000px) {
  .rightSectionImage {
    width: 510px;
    height: auto;
  }
  .headingText {
    font-size: 45px;
    line-height: 54px;
  }
  .chargeContainer {
    padding: 100px 30px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .rightSectionImage {
    width: 550px;
    height: auto;
  }
  .headingText {
    font-size: 45px;
    line-height: 54px;
  }
  .chargeContainer {
    padding: 90px 30px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .growGraphImage {
    width: 610px;
    height: auto;
  }

  .rightSectionImage {
    width: 605px;
    height: auto;
  }
  .headingText {
    font-size: 45px;
    line-height: 54px;
  }
  .chargeContainer {
    padding: 90px 30px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .growGraphImage {
    width: 650px;
    height: auto;
  }

  .rightSectionImage {
    width: 650px;
    height: auto;
  }
  .headingText {
    font-size: 45px;
    line-height: 54px;
  }
  .chargeContainer {
    padding: 90px 30px;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .growGraphImage {
    width: 700px;
    height: auto;
  }

  .rightSectionImage {
    width: 700px;
    height: auto;
  }
  .headingText {
    font-size: 45px;
    line-height: 54px;
  }
  .chargeContainer {
    padding: 90px 30px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .growGraphImage {
    width: 700px;
    height: auto;
  }

  .rightSectionImage {
    width: 755px;
    height: auto;
  }
  .headingText {
    font-size: 60px;
    line-height: 65px;
  }
  .chargeContainer {
    padding: 90px 30px;
  }
}

// for testimonialslider

// for text

.testiMainContainer {
  margin-bottom: 100px;
  margin-top: 100px;
  position: relative;
}

.testiMainContainer img {
  z-index: 100;
  position: absolute;
  left: 150px;
  top: -50px;
}

blockquote {
  padding-inline: 20%;
  text-align: left;
  background-color: #f9f9f9;
}

blockquote p {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  /* or 158% */
  text-align: left;

  letter-spacing: 0.02em;

  color: #000000;
}

blockquote cite {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  /* identical to box height, or 222% */

  text-align: left;
  letter-spacing: 0.02em;

  color: #858585;
}

//   for the indicators at the bottom

.testiMainContainer .slick-dots {
  position: absolute;
  bottom: 20px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
}

.testiMainContainer .slick-dots li {
  margin: 0 5px;
}

.testiMainContainer .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #999;
  border: none;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.5s;
}

.testiMainContainer .slick-dots li.slick-active button {
  opacity: 1 !important;
}

//   for mobile devices
@media screen and (max-width: 576px) {
  .testiMainContainer {
    margin-bottom: 120px;
    margin-top: 120px;
    margin: 150px 16px 120px 16px;
    position: relative;
  }

  .testiMainContainer img {
    width: 26px;
    height: 26px;
    z-index: 100;
    position: absolute;
    left: 25px;
    top: -20px;
  }

  blockquote {
    padding-inline: 15%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    background-color: #f9f9f9;
  }

  blockquote p {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* or 136% */

    letter-spacing: 0.01em;

    color: #000000;
  }

  blockquote cite {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    /* or 110% */

    text-align: center;
    letter-spacing: 0.02em;

    color: #858585;
  }

  //   for the indicators at the bottom

  .testiMainContainer .slick-dots {
    position: absolute;
    bottom: 20px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .testiMainContainer .slick-dots li {
    margin: 0 5px;
  }

  .testiMainContainer .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #999;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.5s;
  }

  .testiMainContainer .slick-dots li.slick-active button {
    opacity: 1 !important;
  }
}

// testimonial slider code ends
